@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul,
li {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}
body {
  font-family: "Poppins", sans-serif;
}
a {
  text-decoration: none !important;
  color: inherit;
}
.btn-primary {
  background-color: #247001 !important;
  border: 1px solid #247001 !important;
  border-radius: 8px !important;
  box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.4);
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.btn-primary:hover {
  transform: scale(1.05);
}
.btn-primary:focus {
  box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.4) !important;
}

.logo {
  height: 60px;
  width: auto;
}

.header {
  background: linear-gradient(180deg, #e9f3f2, #fff);
  padding-top: 30px;
  padding-bottom: 30px;
}
.banner .header {
  background: transparent;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navigation {
  display: flex;
}
/* .nav-item {
  color: #21282b !important;
  font-weight: 300;
  cursor: pointer;
} */
/* .nav-item:not(:last-child) {
  margin-right: 4rem !important;
} */
.toggle-bar {
  display: none;
}

/* Banner */
.banner {
  min-height: 100vh;
  background: linear-gradient(180deg, #e9f3f2, #fff);
}
.banner-section {
  min-height: 80vh;
  display: flex;
  align-items: center;
}
.banner-content h1 {
  color: #e89102;
  font-weight: 700;
  font-size: 4rem;
  margin-bottom: 0;
}
.banner-content h2 {
  color: #21282b;
  font-weight: 700;
  font-size: 4rem;
  margin-bottom: 20px;
}
.banner-content p {
  color: #79858b;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
}
.banner-img {
  width: 100%;
}
/* Services */
.services-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.main-title {
  text-align: center;
  color: #21282b;
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 600;
}
.sub-title {
  text-align: center;
  color: #79858b;
  font-weight: 300;
  margin-bottom: 80px;
}
.services-single {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.services-img {
  height: 150px;
  width: auto;
  margin-bottom: 20px;
}
.services-single h5 {
  color: #21282b;
  font-weight: 600;
  margin-bottom: 10px;
}
.services-single p {
  color: #79858b;
  font-weight: 300;
  text-align: center;
  width: 60%;
  font-size: 14px;
}

/* steps */
.steps-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.steps-single {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}
.steps-img {
  height: 120px;
  width: auto;
  margin-bottom: 20px;
}
.steps-single p {
  color: #247001;
  font-size: 16px;
  margin-bottom: 10px;
}
.steps-single h5 {
  color: #21282b;
  font-size: 18px;
  font-weight: 500;
  width: 60%;
  text-align: center;
}

/* About */
.about-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.about-img-wrapper {
  display: flex;
  justify-content: center;
}
.about-img {
  height: 400px;
}
.about-content-wrapper {
  padding-top: 50px;
}
.about-content-wrapper h1 {
  text-align: left;
}
.about-content-wrapper p {
  color: #79858b;
  text-align: justify;
  font-weight: 300;
  margin-bottom: 30px;
}

/* Booking */
.booking-section {
  padding-top: 150px;
  padding-bottom: 50px;
}
.booking-wrapper {
  background-color: #154c25;
  min-height: 300px;
  border-radius: 64px;
  padding: 50px 80px;
  position: relative;
}
.booking-content h1 {
  color: #fff;
  margin-bottom: 20px;
}
.booking-content p {
  color: #cfd1d1;
  font-weight: 300;
  margin-bottom: 50px;
}
.booking-img {
  position: absolute;
  bottom: 0;
  right: 15%;
}
.booking {
  height: 400px;
}
.comming-soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.comming-soon img {
  height: 180px;
  margin-bottom: 10px;
}
.comming-soon p {
  color: #707070;
  font-weight: 300;
}
/* gallery */
.gallery-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.gallery-img-lg {
  height: 500px;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
}
.gallery-img-sm {
  height: 240px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* Blog wrapper */
.blog-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.blog-wrapper {
  margin-top: 30px;
  background-color: #f3f3f3;
  border-radius: 64px;
  padding: 30px;
  display: flex;
  justify-content: center;
}
.comming-img {
  height: 260px;
  margin-right: 30px;
}
.blog-wrapper h5 {
  color: #707070;
  letter-spacing: 1px;
}
.blog-wrapper p {
  color: #707070;
  margin-bottom: 0;
  font-weight: 300;
}

/* Modal */
.modal-dialog {
  /* width: 800px !important; */
  border-radius: 32px;
}
.modal-title {
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px !important;
}
.modal-subtitle {
  font-size: 14px;
  text-align: center;
  color: #707070;
  font-weight: 300;
}
@media (min-width: 576px) {
  .modal-dialog-lg .modal-dialog {
    max-width: 800px !important;
  }
}
.modal-content {
  border-radius: 16px !important;
  min-height: 400px;
}
.close-icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

/* Tabs */
.nav-tabs {
  border-bottom: none !important;
  display: flex;
  justify-content: center;
}
.nav-tabs .nav-link {
  border: none !important;
  color: #21282b;
  padding: 0 !important;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: none;
  color: #247001;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
  color: #247001 !important;
}
.tab-content {
  padding: 50px;
}

.single-plant {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}
.plant-img {
  height: 120px;
  margin-bottom: 20px;
}
.plant-name {
  font-size: 18px;
}
.plant-rate {
  color: #707070;
  font-size: 14px;
}

/* Partners */
.partners-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.partner-single {
  display: flex;
  align-items: center;
  margin-right: 50px;
}
.partners-wrapper {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partner-single h5 {
  color: #707070;
  font-weight: 300;
  margin-right: 20px;
  letter-spacing: 1px;
}

/* Contact */
.contact-section {
  background-color: #e8e8e8;
  padding-top: 50px;
  padding-bottom: 50px;
}
.contact-wrapper {
  padding-top: 50px;
  width: 400px;
  margin: 0 auto;
}
label {
  color: #707070;
  font-weight: 300;
}
.contact-section .form-control {
  border: none !important;
}
::placeholder {
  color: #cecece !important;
}

/* Footer */
.footer-top {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f9fbfb;
}
.footer-top .footer-logo {
  height: 60px;
}
.footer-title {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 10px;
}
.footer-subtitle {
  font-weight: 300;
  color: #707070;
  font-size: 14px;
}
.footer-bottom {
  background-color: #e4f2f2;
  padding: 10px;
}
.footer-bottom p {
  margin-bottom: 0;
  color: #707070;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
}
.useful-links li {
  font-weight: 300;
  color: #707070;
  font-size: 14px;
  margin-bottom: 10px !important;
}
.social-icons {
  display: flex;
  color: #707070;
}
.social-icons li:not(:last-child) {
  margin-right: 10px !important;
}
.footer-contact li {
  display: flex;
  color: #707070;
  font-weight: 300;
  margin-bottom: 10px !important;
}
.icon-wrapper {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.signup {
  height: 120px;
}

.page-content {
  padding-bottom: 50px;
}
.about-page-img {
  height: 400px;
  border-radius: 16px;
  margin-bottom: 30px;
}
.about-page-wrapper h1 {
  text-align: left;
  font-size: 2rem;
  color: rgb(31, 31, 31);
}
.about-page-wrapper p {
  color: #79858b;
  text-align: justify;
  font-weight: 300;
  margin-bottom: 30px;
}

/* Media Query */
@media only screen and (max-width: 420px) {
  .navigation {
    display: none;
  }
  .toggle-bar {
    display: block;
  }
  .header-wrapper .btn-wrapper {
    display: none;
  }
  .banner-content h1 {
    font-size: 3rem;
  }
  .banner-content h2 {
    font-size: 3rem;
  }
  .booking {
    height: 150px;
  }
  .gallery-img-lg {
    height: 240px;
    margin-bottom: 30px;
  }
  .blog-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .partners-wrapper {
    flex-direction: column;
  }
  .partner-single {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .contact-wrapper {
    width: 100%;
  }
}

.button_container {
  position: relative;
  /* top: 5%; */
  /* right: 2%; */
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.25s ease;
}

.button_container:hover {
  opacity: 0.7;
}

.button_container.active .top {
  transform: translateY(11px) translateX(0) rotate(45deg);
  background: #fff;
}

.button_container.active .middle {
  opacity: 0;
  background: #fff;
}
.button_container.active .bottom {
  transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #fff;
}

.button_container span {
  /* background: #ff5252; */
  background: #e89102;
  /* background: #247001; */
  border: none;
  height: 5px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.35s ease;
  cursor: pointer;
}
.button_container span:nth-of-type(2) {
  top: 11px;
}
.button_container span:nth-of-type(3) {
  top: 22px;
}

.overlay {
  position: fixed;
  /* background: #e89102; */
  /* background: #247001; */
  /* background: rgb(36, 112, 1); */
  background: linear-gradient(210deg, rgb(36, 112, 1) 0%, #affa68 100%);

  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
}
.overlay.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
  z-index: 99;
}

.overlay.open li {
  animation: fadeInRight 0.5s ease forwards;
  animation-delay: 0.35s;
}

.overlay.open li:nth-of-type(2) {
  animation-delay: 0.4s;
}
.overlay.open li:nth-of-type(3) {
  animation-delay: 0.45s;
}
.overlay.open li:nth-of-type(4) {
  animation-delay: 0.5s;
}

.overlay nav {
  position: relative;
  height: 70%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 50px;
  font-weight: 400;
  text-align: center;
}
.overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%;
}
.overlay ul li {
  display: block;
  height: calc(100% / 5);
  min-height: 50px;
  position: relative;
  opacity: 0;
  color: #fff;
  cursor: pointer;
}

/* .overlay ul li:hover:after,
:focus:after,
:active:after {
  width: 100%;
}

.overlay ul li:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  transform: translateX(-50%);
  height: 3px;
  background: #fff;
  transition: 0.35s;
} */

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
